import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";

export const query = graphql`
  query {
    szansy: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    komunikator: file(relativePath: { eq: "komunikator.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ogloszenia: file(relativePath: { eq: "ogloszenia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Komunikator = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Komunikacja w zespole"
      headline="Usprawnij obieg informacji"
    >
      <SEO
        title="Komunikacja w zespole"
        description="Komunikacja w zespole ✅ Usprawnij obieg informacji • Monitorowanie aktywności handlowców • Zapytaj o bezpłatne demo!"
        keywords={["komunikator"]}
      />
      <p>
        <strong>Zlecenia</strong>, które realizowane są w{" "}
        <strong>agencjach reklamowych</strong>, wymagają zazwyczaj{" "}
        <strong>zaangażowania całego zespołu</strong> ludzi - i to z różnych
        działów. Kto inny ustala warunki z klientem, kto inny projektuje stoisko
        promocyjne i kto inny je wykonuje. Dlatego w branży reklamowej tak ważny
        jest <strong>sprawny przepływ wiedzy pomiędzy zespołami</strong>.
        Empireo jest gotowe wesprzeć Cię na tym polu, oferując narzędzia takie
        jak <strong>komunikator oraz ogłoszenia firmowe</strong>.
      </p>

      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.komunikator, data.ogloszenia]}
        alts={[
          "Sprawny przeływ informacji w systemie dla agencji reklamowej, Ogłoszenia dla pracowników w systemie dla agencji reklamowej",
        ]}
      />
      <h3>Kluczowe możliwości:</h3>
      <p>
        <ul>
          <li>
            <strong>Komunikator</strong> z całym garniturem funkcjonalności, od
            przypomnień, poprzez historię aż po wyszukiwarkę wiadomości. Masz
            też możliwość powiązania wiadomości z dokumentem, zleceniem,
            zadaniem, szansą sprzedaży czy wiadomością mailową.
          </li>
          <li>
            <strong>Ogłoszenia firmowe</strong> - dzięki którym w łatwy sposób
            dotrzesz z ważnym komunikatem do wybranej grupy pracowników.
          </li>
        </ul>
      </p>
      <p
        style={{
          marginTop: "30px",
        }}
      >
        Empireo zapewni Twojej agencji reklamowej{" "}
        <strong>sprawną, elastyczną komunikację</strong> oraz ułatwi
        przekazywanie wiedzy pomiędzy zespołami. Dzięki funkcjom komunikatora
        przedmiot dyskusji zawsze będzie jasny, a wszystkie ustalenia
        zarchiwizowane. Ogłoszenia sprawią, że{" "}
        <strong>pracownicy zawsze będą na czasie</strong>.
      </p>
    </FunctionPageLayout>
  );
};

export default Komunikator;
